import React from "react";

import { IonCard, IonIcon, isPlatform, useIonAlert } from "@ionic/react";
import { cameraOutline, imagesOutline } from "ionicons/icons";
import { Controller, ControllerRenderProps, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { uploadImages } from "api/images";
import { IImage } from "interfaces/IImage";
import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import LocalImage from "models/LocalImage";
import { useAppSelector } from "store";

import useFieldTemplate from "../../hooks/useFieldTemplate";
import buildFilePath from "../../utils/buildFilePath";
import { resizeImageFile } from "./utils/imageResize";

import "./Camera.styles.css";

interface IProps {
	field: FormField<IImage[]>;
	formMethods: UseFormReturn<FormValues>;
	numberImages: number;
}

type PropsType = IProps;
const Camera: React.FC<PropsType> = (props) => {
	const { field, formMethods, numberImages } = props;
	const { name } = useFieldTemplate(field, formMethods.control);
	const { projectRef, assetId, recordId } = useAppSelector((state) => state.form);
	const historySlice = useAppSelector((state) => state.history);
	const { t, i18n } = useTranslation();

	const [presentAlert] = useIonAlert();

	const uploadingError = i18n.format(t("uploading_error"), "capitalize");

	const onChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
		fieldRenderProps: ControllerRenderProps<FormValues<IDataValue>, string>,
	) => {
		const files = e.target.files;
		if (!files || !projectRef || !assetId || !recordId) return;
		try {
			const images: LocalImage[] = [];
			for (const [idx, file] of Array.from(files).entries()) {
				const filepath = buildFilePath(projectRef, assetId, recordId, idx, "jpeg");
				const reduced = await resizeImageFile(file, 1024).catch((err) => {
					console.error(err);
					return file;
				});
				if (file.size === 0) {
					console.error(`File ${file.name} is empty. ${filepath}`);
				}
				const localImg = new LocalImage({
					filepath: filepath,
					formpath: `${historySlice.absolutePath}.${field.name}`,
					file: reduced,
					project_ref: projectRef,
					asset_id: assetId,
					record_id: recordId,
					size: reduced.size,
				});
				images.push(localImg);
			}
			await Promise.all(images.map((img) => img.save()));
			// Attempt to eagerly upload all new images in the background
			uploadImages(images)
				.then(() => {
					console.log("Succesfully uploaded all images. Proceeding to local deletion.");
					images.map((img) => img.delete());
				})
				.catch(async (err) => {
					console.warn("Error eagerly uploading images");
					console.warn(err);
				});
			await fieldRenderProps.onChange(
				[...((fieldRenderProps.value as IImage[]) || []), ...images]
					.filter((it) => (it as IImage)._is_deleted !== true)
					.map((ph) => ({
						filepath: ph.filepath,
						formpath: ph.formpath,
					})),
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			console.error(err);
			await presentAlert(uploadingError, [{ text: "OK", role: "cancel" }]);
		} finally {
			// This should reset the form input (so that the same file can be picked again)
			const input = e.target;
			input.value = "";
			if (!/safari/i.test(navigator.userAgent)) {
				input.type = "";
				input.type = "file";
			}
		}
	};

	const belowLimit: boolean = field.max === undefined || numberImages < Number(field.max);

	return (
		<>
			<Controller
				name={name}
				control={formMethods.control}
				render={({ field: fieldRenderProps }) => {
					return (
						<>
							{belowLimit && (isPlatform("android") || isPlatform("ios")) && (
								<IonCard className="cameraButton" type="button" color={"#EFEFEF"}>
									<IonIcon
										style={{
											fontSize: "2rem",
											color: "var(--ion-color-gray500)",
											display: "block",
										}}
										icon={cameraOutline}
									/>
									<div style={{ display: "hidden" }}>
										<input
											type="file"
											accept="image/*"
											capture="environment"
											multiple
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												onChange(e, fieldRenderProps);
											}}
										/>
									</div>
								</IonCard>
							)}
							{belowLimit && (
								<IonCard className="cameraButton" type="button" color={"#EFEFEF"}>
									<IonIcon
										style={{
											fontSize: "2rem",
											color: "var(--ion-color-gray500)",
											display: "block",
										}}
										icon={imagesOutline}
									/>
									<div style={{ display: "hidden" }}>
										<input
											type="file"
											accept="image/*"
											multiple
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												onChange(e, fieldRenderProps);
											}}
										/>
									</div>
								</IonCard>
							)}
						</>
					);
				}}
			/>
		</>
	);
};
export default Camera;
