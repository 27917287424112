import React, { useState } from "react";

import { IonActionSheet, IonButton, IonButtons, IonHeader, IonIcon, IonTitle, IonToolbar } from "@ionic/react";
import { close, save, trash } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { arrowLeft } from "assets/icons";
import { useAssetName } from "components/common/Form/utils/useAssetName";
import { useAppSelector } from "store";

import { TopbarProps } from "./TopbarProps";
import { useTopbar } from "./useTopbar";

export const EmbeddedTopbar: React.FC<TopbarProps> = (props) => {
	const { formFields, formMethods, onSubmit } = props;

	const { projectRef } = useParams<{
		projectRef: string;
	}>();
	const { t, i18n } = useTranslation();
	const historySlice = useAppSelector((store) => store.history);
	const { assetId, recordId, isFrozen } = useAppSelector((state) => state.form);
	const fieldLibrary = useAppSelector((store) => store.form.fieldReferenceLibrary);
	const { assetName } = useAssetName(projectRef, assetId);
	const [confirmDiscardFlag, setConfirmDiscardFlag] = useState<"exit" | "back">();

	const { handleBack, handleSave, handleDiscard } = useTopbar({
		fieldLibrary,
		formFields,
		isFrozen,
		confirmDiscardFlag,
		setConfirmDiscardFlag,
		onSubmit,
		exitForm: () => undefined,
		formMethods,
		recordId,
	});

	return (
		<React.Fragment>
			<IonHeader mode="ios">
				<IonToolbar style={{ alignItems: "center", "--border-width": 0 }}>
					{historySlice.list.length > 0 && (
						<IonButtons slot="start">
							<IonButton
								fill="clear"
								color="secondary"
								data-testid="formBackButton"
								style={{ marginTop: 0, marginBottom: 0 }}
								onClick={() => handleBack(false)}
							>
								<IonIcon
									icon={arrowLeft}
									size="small"
									style={{
										strokeWidth: "50%",
										color: "#718096",
										cursor: "pointer",
									}}
								/>
							</IonButton>
						</IonButtons>
					)}
					<IonTitle
						style={{
							// display: "flex",
							// justifyContent: "center",
							fontSize: "0.666rem",
							fontWeight: "400",
						}}
					>
						{assetName}
					</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonActionSheet
				isOpen={confirmDiscardFlag !== undefined}
				header={i18n.format(t("discard_changes_msg"), "capitalize")}
				buttons={[
					{
						text: i18n.format(t("save"), "capitalize"),
						icon: save,
						id: "saveChanges",
						handler: () => handleSave(),
					},
					{
						text: i18n.format(t("discard"), "capitalize"),
						icon: trash,
						id: "discardChanges",
						role: "destructive",
						handler: handleDiscard,
					},
					{
						text: i18n.format(t("cancel"), "capitalize"),
						icon: close,
						id: "cancelChanges",
						role: "cancel",
					},
				]}
				onDidDismiss={() => setConfirmDiscardFlag(undefined)}
			/>
		</React.Fragment>
	);
};
