import devConfig from "./dev";
import hpConfig from "./hp";
import localConfig from "./local";
import prodConfig from "./prod";

export interface IConfig {
	ENV: "local" | "dev" | "prod" | "hp";
	API: string;
	DHUB: string;
	RELEASE: string;
	AZURE_AD_CLIENT_ID: string;
	AZURE_AD_TENANT_ID: string;
}

let config: IConfig = localConfig;

if (window.location.hostname === "localhost" && process.env.REACT_APP_ENV === "development") config = devConfig;
else if (window.location.hostname === "localhost") config = localConfig;
else if (window.location.hostname.includes("forms.dev.")) config = devConfig;
else if (window.location.hostname.includes("forms.hp.")) config = hpConfig;
else config = prodConfig;

export default config;
