import { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";
import { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { useAppSelector } from "store";
import { buildContext, getDependencies } from "../../../hooks/useFieldContext";
import { evaluateExpression } from "../../../utils/evaluateExpression";

interface ItemWithDetails {
	index: number;
	refNo: number;
	title: string;
	subtitle?: string;
	iconColor: string;
	iconSrc: string;
	item: FormValues;
}

const useRepeatableItems = (field: FormField, formMethods: UseFormReturn<FormValues>, history: string[]) => {
	// TODO: for some unknown reason some times nulls are retrieved from the items list
	const items = (useWatch({ name: history.join("."), control: formMethods.control }) || []) as (FormValues | null)[];
	const { fieldReferenceLibrary, baseContext } = useAppSelector((state) => state.form);

	const withDetails: ItemWithDetails[] = useMemo(() => {
		return items
			.map((item, index) => ({
				item,
				index,
			}))
			.sort((prev, next) => {
				const prevDateCreated: string = (prev.item?._date_created as string) || "";
				const nextDateCreated: string = (next.item?._date_created as string) || "";
				return prevDateCreated.localeCompare(nextDateCreated);
			})
			.map((it, idx) => ({ ...it, refNo: idx + 1 }))
			.filter(({ item }) => !!item && item !== null && !!item.id && !item._is_deleted) // Remove nulls, if any
			.map(({ item, index, refNo }, idx) => {
				const nonNullItem = item as FormValues<IDataValue>;

				const { dependencies, rawDependencies } = getDependencies(field, fieldReferenceLibrary, [
					...history,
					`${index}`,
				]);

				const rawContext = formMethods.getValues(
					dependencies.filter((dep) => !dep.includes("_")).map((dep) => dep.join(".")),
				);
				const context = buildContext(dependencies, rawDependencies, rawContext, {
					...baseContext,
					...field.baseContext,
				});

				const fallbackTitle = `${field.label} ${idx + 1}`;
				const title = field?.itemTitle
					? evaluateExpression<string>(field.itemTitle, context, fallbackTitle)
					: fallbackTitle;
				const subtitle = field?.itemSubtitle
					? evaluateExpression<string>(field.itemSubtitle, context, undefined)
					: undefined;
				const iconColor = field?.itemIconConfig?.color
					? evaluateExpression<string>(field.itemIconConfig.color, context, "gray")
					: "gray";
				const iconSrc = field?.itemIconConfig?.icon
					? evaluateExpression<string>(field.itemIconConfig.icon, context, "ellipse")
					: "ellipse";
				return {
					index,
					refNo,
					title,
					subtitle,
					iconColor,
					iconSrc,
					item: nonNullItem,
				};
			});
	}, [items]);

	if (!field.sortBy) return withDetails;
	const collator = new Intl.Collator(undefined, {
		numeric: true,
		sensitivity: "base",
	});
	return (
		withDetails
			// .sort((itemA, itemB) => {
			// 	return itemA.item._date_created.localeCompare(valueB.item._date_created);
			// })
			.sort((itemA, itemB) => {
				const sortFields = field.sortBy === "title" ? ["title", "subtitle"] : ["subtitle", "title"];

				for (const sortField of sortFields) {
					const valueA: string | undefined = itemA[sortField as "title" | "subtitle"];
					const valueB: string | undefined = itemB[sortField as "title" | "subtitle"];

					// Nulls sort after anything else
					if (!valueA) return 1;
					if (!valueB) return -1;
					// Otherwise, compare strings
					const res =
						!field.sortMode || field.sortMode === "ascending"
							? collator.compare(valueA || "", valueB || "")
							: collator.compare(valueB || "", valueA || "");
					if (res !== 0) return res;
				}
				return 0;
			})
	);
};

export default useRepeatableItems;
