import {
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonSpinner,
	IonText,
} from "@ionic/react";
import { alertTriangle } from "assets/icons";
import { Button } from "components/common/Button";
import { checkmarkCircleOutline, closeOutline, ellipseOutline } from "ionicons/icons";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord from "models/FormRecord";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { isNodeCompleted } from "utils/isNodeCompleted/isNodeCompleted";

interface IProps {
	isOpen: boolean;
	onButtonClick: () => Promise<void>;
	onClose: () => void;
}

interface IState {
	name: string;
	incompleteFormNames: string[];
	isComplete: boolean;
}

export const ConfirmDeliveryModal: React.FC<IProps> = (props: IProps) => {
	const { isOpen, onButtonClick, onClose } = props;
	const { t, i18n } = useTranslation();
	const { assetId } = useParams<{ assetId: string }>();
	const [state, setState] = useState<IState>();
	const [inputValid, setInputValid] = useState(false);
	const [inProgress, setInProgress] = useState(false);

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			if (!assetId) return;
			const asset = await Asset.get(assetId);
			const records = await FormRecord.byAssetId(assetId, { includeDeleted: false, includeInactive: false });

			const completenessResults: [string, boolean][] = await Promise.all(
				records.map(async (record) => {
					const form = await Form.get(record.form_id);
					return [form.name, await isNodeCompleted(form, assetId, record.data)];
				}),
			);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const incompleteFormNames = completenessResults.filter(([_, isComplete]) => !isComplete).map(([name]) => name);
			if (isCancelled) return;
			setState({ name: asset.name, incompleteFormNames, isComplete: completenessResults.every((it) => it[1]) });
		};
		initialize();
		return () => {
			isCancelled = true;
		};
	}, [assetId, open]);

	return (
		<IonModal isOpen={isOpen} canDismiss={true} backdropDismiss={true}>
			<IonHeader
				style={{
					height: "3rem",
					background: "var(--ion-background-color, #fff)",
				}}
			>
				<IonItem lines="none">
					<IonLabel
						style={{
							marginLeft: "3rem",
							display: "flex",
							justifyContent: "center",
							fontSize: "0.875rem",
							fontWeight: "400",
						}}
					>
						{`Entrega ${state?.name ?? ""}`}
					</IonLabel>
					<IonIcon
						style={{
							height: "100%",
							width: "2rem",
							color: "black",
							alignSelf: "center",
							paddingTop: "0.5rem",
							paddingBottom: "0.5rem",
							cursor: "pointer",
						}}
						icon={closeOutline}
						onClick={onClose}
					/>
				</IonItem>
			</IonHeader>
			{!state ? (
				<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
					<IonSpinner />
				</div>
			) : (
				<IonContent forceOverscroll={false}>
					<div
						style={{
							height: "100%",
							display: "flex",
							flexDirection: "column",
							gap: "3rem",
							padding: "2rem",
						}}
					>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								gap: "1rem",
								width: "100%",
							}}
						>
							<IonIcon style={{ fontSize: "5rem" }} icon={alertTriangle} />
							{state.isComplete ? (
								<IonText style={{ textAlign: "justify" }}>
									{i18n.format(t("delivery_no_reversible_operation"), "capitalize")}
								</IonText>
							) : (
								<div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
									<IonText style={{ textAlign: "justify", fontWeight: "600" }}>
										{i18n.format(t("delivery_incomplete_error"), "capitalize")}
									</IonText>
									<IonText style={{ textAlign: "justify" }}>
										{i18n.format(t("delivery_incomplete_info_intro"), "capitalize")}
									</IonText>
									<div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
										<IonIcon src={ellipseOutline} color="medium" style={{ fontSize: "1.5rem" }} />
										<IonText>{i18n.format(t("delivery_incomplete_info_incomplete_icon"), "capitalize")}</IonText>
									</div>
									<div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
										<IonIcon src={checkmarkCircleOutline} color="success" style={{ fontSize: "1.5rem" }} />
										<IonText>{i18n.format(t("delivery_incomplete_info_complete_icon"), "capitalize")}</IonText>
									</div>
									<IonText>{i18n.format(t("delivery_incomplete_summary"), "capitalize")}</IonText>
								</div>
							)}
						</div>
						{state.isComplete && (
							<IonItem style={{ marginInline: "1rem" }}>
								<IonInput
									label={i18n.format(t("delivery_input_label"), "capitalize")}
									labelPlacement="stacked"
									onIonInput={(ev) => {
										const value = ev.detail.value;
										setInputValid(value === "entregar" || value === "delivery");
									}}
								/>
							</IonItem>
						)}
						<div style={{ marginTop: "auto", display: "flex", flexDirection: "column" }}>
							<Button
								variant="filled"
								onClickFunction={() => {
									setInProgress(true);
									onButtonClick().then(() => setInProgress(false));
								}}
								disabled={!inputValid || inProgress || !state.isComplete}
							>
								{inProgress ? (
									<IonSpinner name="crescent" />
								) : (
									i18n.format(t("delivery_confirmation_button"), "capitalize")
								)}
							</Button>
						</div>
					</div>
				</IonContent>
			)}
		</IonModal>
	);
};
