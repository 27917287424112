import { IRecordSummary } from "api/records";
import FormRecord from "models/FormRecord";
import Asset from "../models/Asset";
import Form, { FormField } from "../models/Form";
import Project from "../models/Project";

const fields: FormField[] = [
	{
		name: "carpeta1",
		type: "drillDownGroup",
		label: "Carpeta 1",
		required: true,
		children: [
			{
				name: "carpeta2",
				type: "inlineGroup",
				label: "Carpeta 2",
				required: true,
				children: [
					{
						name: "carpeta3",
						type: "inlineGroup",
						label: "Carpeta 3",
						required: true,
						children: [
							{
								// To check that the text does not disappear when clicking on dropdown
								name: "debug-text-syle",
								label: "Text to check style",
								type: "text",
							},
							{
								name: "debug-dropdown-inline",
								label: "Dropdown demo",
								type: "dropdown",
								choices: [
									{ value: "a", label: "A" },
									{ value: "b", label: "B" },
								],
							},
							{
								name: "debugRadio-inline",
								label: "Radio demo",
								type: "radio",
								choices: [
									{ value: "a", label: "A" },
									{ value: "b", label: "B" },
								],
							},
							{
								name: "debug-textarea-inline",
								label: "Text area demo",
								type: "textarea",
							},
							{
								name: "debug-location-inline",
								label: "Location demo",
								type: "location",
							},
							{
								name: "debug-images-inline",
								label: "Images demo",
								type: "images",
							},
							{
								name: "debug-sketch-inline",
								label: "Sketch demo",
								type: "sketch",
							},
							{
								name: "debug-files-inline",
								label: "Files demo",
								type: "files",
							},
							{
								name: "debug-disclaimer-inline",
								label:
									"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Natoque penatibus et magnis dis. Risus nullam eget felis eget. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Cursus in hac habitasse platea dictumst quisque. Pellentesque adipiscing commodo elit at. Vitae tortor condimentum lacinia quis vel eros donec ac odio. Risus commodo viverra maecenas accumsan lacus vel facilisis volutpat. Odio tempor orci dapibus ultrices in iaculis nunc. Posuere sollicitudin aliquam ultrices sagittis orci. Suscipit adipiscing bibendum est ultricies integer quis.",
								type: "disclaimer",
							},
							{
								name: "debug-link-inline",
								label: "https://www.google.com",
								shortLabel: "This is a link (B)",
								type: "link",
							},
						],
					},
				],
			},
			{
				name: "debug-text",
				label: "Text demo",
				type: "text",
			},
			{
				name: "debugNumber",
				label: "Number between 0 and 10",
				type: "number",
				min: 0,
				max: 10,
			},
			{
				name: "debugDropdown",
				label: "Dropdown demo",
				type: "dropdown",
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debugDropdownMultiple",
				label: "Multi dropdown demo",
				type: "dropdown",
				multiple: true,
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debugRadio",
				label: "Radio demo",
				type: "radio",
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debugRadioMultiple",
				label: "MultiRadio demo",
				type: "radio",
				multiple: true,
				choices: [
					{ value: "a", label: "A" },
					{ value: "b", label: "B" },
				],
			},
			{
				name: "debug-textarea",
				label: "Text area demo",
				type: "textarea",
			},
			{
				name: "debug-location",
				label: "Location demo",
				type: "location",
			},
			{
				name: "debug-drawings",
				label: "Drawings demo",
				type: "drawings",
				validate: "$ !choice.assetId || assetId == choice.assetId",
				choices: [
					{ value: "drawing1", label: "Drawing 1", filepath: "bugs.jpeg" },
					{ value: "drawing2", label: "Drawing 2", filepath: "57.jpeg" },
					{
						value: "drawing2",
						label: "Drawing only for asset 1",
						filepath: "bugs.jpeg",
						assetId: "debug-1",
					},
					{
						value: "drawing2",
						label: "Drawing only for asset 2",
						filepath: "57.jpeg",
						assetId: "debug-2",
					},
				],
			},
			{
				name: "debug-images",
				label: "Images demo",
				type: "images",
			},
			{
				name: "debug-sketch",
				label: "Sketch demo",
				type: "sketch",
			},
			{
				name: "debug-files",
				label: "Files demo",
				type: "files",
			},
			{
				name: "debug-disclaimer-inline",
				label:
					"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
				type: "disclaimer",
			},
			{
				name: "debug-link-inline",
				label: "https://www.google.com",
				shortLabel: "This is a link (A)",
				type: "link",
			},
			{
				name: "debugCalculationInput",
				label: "Calculation input",
				type: "text",
			},
			{
				name: "debugCalculationResult",
				label: "Calculation result",
				type: "calculation",
				alert:
					'$debugCalculationInput ? "" : "No input value! No input value! No input value! No input value! No input value! No input value!"',
				alertColor: "primary",
				calculation: "$ formatCalculation(10 / debugCalculationInput)",
				dependsOn: ["debugCalculationInput"],
			},
			{
				name: "debugCalculationResult2",
				label: "Calculation result 2",
				type: "calculation",
				alert: '$debugCalculationInput ? "" : "No input value!"',
				alertColor: "primary",
				calculation: "$ add([5, debugCalculationInput, debugCalculationResult])",
				dependsOn: ["debugCalculationInput", "debugCalculationResult"],
			},
			{
				name: "debugDependsOn1",
				label: "Text depends on 1",
				type: "text",
				dependsOn: ["debugDropdown"],
				relevant: '$ includes(debugDropdown,"a")',
			},
			{
				name: "debugDependsOn2",
				label: "Text depends on 2",
				type: "text",
				dependsOn: ["debugDropdown"],
				relevant: '$ includes(debugDropdown,"b")',
			},
		],
	},
	{
		name: "drilldown1",
		type: "drillDownGroup",
		label: "Drilldown 1",
		required: true,
		children: [
			{
				name: "drilldown2",
				type: "drillDownGroup",
				label: "Drilldown 2",
				required: true,
				children: [
					{
						type: "dropdown",
						multiple: true,
						name: "outer-multiselect",
						label: "Outer Multi Select",
						defaultValue: ["a", "b", "c", "d"],
						choices: [
							{ label: "A", value: "a" },
							{ label: "B", value: "b" },
							{ label: "C", value: "c" },
							{ label: "D", value: "d" },
						],
					},
					{
						name: "drilldown3",
						type: "drillDownGroup",
						label: "Drilldown 3",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion",
								label: "Observaciones",
							},
							{
								type: "dropdown",
								multiple: true,
								name: "multiselect",
								label: "Inner Multi Select",
								defaultValue: ["a", "d"],
								choices: [
									{ label: "A", value: "a" },
									{ label: "B", value: "b" },
									{ label: "C", value: "c" },
									{ label: "D", value: "d" },
								],
							},
							{
								type: "dropdown",
								name: "select",
								label: "Select one of above (dependency with name only)",
								choices: [
									{ label: "A", value: "a" },
									{ label: "B", value: "b" },
									{ label: "C", value: "c" },
									{ label: "D", value: "d" },
								],
								validate: "$choice.value in multiselect",
								dependsOn: ["multiselect"],
							},
						],
					},
				],
			},
		],
	},
	{
		name: "complex1/1",
		type: "drillDownGroup",
		label: "Complex Drilldown 1",
		required: true,
		children: [
			{
				name: "complex2/1",
				type: "inlineGroup",
				label: "2.1",
				required: true,
				children: [
					{
						name: "complex3/1/0",
						type: "text",
						label: "3.1.0",
					},
					{
						name: "complex3/1/1",
						type: "drillDownGroup",
						label: "3.1.1",
						required: true,
						children: [
							{
								name: "complex4/1/1",
								type: "inlineGroup",
								label: "4.1.1",
								required: true,
								children: [
									{
										type: "text",
										name: "drilldownObservacion4/1/1",
										label: "Observaciones",
									},
								],
							},
							{
								name: "complex4/1/2",
								type: "inlineGroup",
								label: "4.1.2",
								required: true,
								children: [
									{
										type: "text",
										name: "drilldownObservacion4/1/2",
										label: "Observaciones",
									},
								],
							},
						],
					},
				],
			},
			{
				name: "complex2/2",
				type: "drillDownGroup",
				label: "2.2",
				required: true,
				children: [
					{
						name: "complex3/2/1",
						type: "drillDownGroup",
						label: "3.2.1",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/2/1",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/2/2",
						type: "drillDownGroup",
						label: "3.2.2",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/2/2",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/2/3",
						type: "drillDownGroup",
						label: "3.2.3",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/2/3",
								label: "Observaciones",
							},
						],
					},
				],
			},
			{
				name: "complex2/3",
				type: "drillDownGroup",
				label: "2.3",
				required: true,
				children: [
					{
						name: "complex3/3/1",
						type: "inlineGroup",
						label: "3.3.1",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/3/1",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/3/2",
						type: "drillDownGroup",
						label: "3.3.2",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/3/2",
								label: "Observaciones",
							},
						],
					},
					{
						name: "complex3/3/3",
						type: "drillDownGroup",
						label: "3.3.3",
						required: true,
						children: [
							{
								type: "text",
								name: "drilldownObservacion3/3/3",
								label: "Observaciones",
							},
						],
					},
				],
			},
		],
	},
	{
		name: "ddg",
		type: "drillDownGroup",
		label: "Grupo con Repetible",
		required: true,
		children: [
			{
				type: "radio",
				multiple: true,
				name: "colors",
				label: "Outer Multi Select for Color",
				defaultValue: ["red", "yellow", "green", "blue"],
				disabled: false,
				choices: [
					{ label: "Red", value: "red" },
					{ label: "Yellow", value: "yellow" },
					{ label: "Green", value: "green" },
					{ label: "Blue", value: "blue" },
				],
			},
			{
				name: "repeatable",
				type: "repeatableGroup",
				label: "Repetible",
				required: true,
				itemTitle: "$nombre",
				itemSubtitle: "$(colorSelect ? colorSelect : '') + ' ' + (iconSelect ? iconSelect : '')",
				itemIconConfig: {
					color: "$colors[colorSelect] || colorSelect",
					icon: "$iconSelect",
					label: "Summary",
				},
				baseContext: {
					colors: { red: "tomato", yellow: "yellow", green: "darkgreen" },
				},
				dependsOn: ["nombre", "colorSelect", "iconSelect"],
				children: [
					{
						type: "text",
						name: "nombre",
						label: "Nombre",
						filter: "exact",
						required: true,
						defaultValue: "Test Value",
					},
					{
						type: "dropdown",
						name: "colorSelect",
						label: "Select one of above (dependency with name only)",
						choices: [
							{ label: "Red", value: "red" },
							{ label: "Yellow", value: "yellow" },
							{ label: "Green", value: "green" },
							{ label: "Blue", value: "blue" },
						],
						validate: "$choice.value in colors",
						sortChoices: "ascending",
						dependsOn: ["colors"],
						filter: "exact",
					},
					{
						type: "dropdown",
						name: "iconSelect",
						label: "Select one of above (dependency with full path, pointing to inner multi select)",
						choices: [
							{ label: "Futbol americano", value: "americanFootball" },
							{ label: "Bolso", value: "bagHandle" },
							{ label: "Globo", value: "balloon" },
							{ label: "Pesas", value: "barbell" },
						],
						defaultValue: "americanFootball",
						filter: "exact",
					},
				],
			},
		],
	},
	{
		name: "crossFormTesting",
		label: "Cross Form Dependencies",
		type: "drillDownGroup",
		children: [
			{
				name: "crossFormText1",
				label: "This field is read for the next field calculation",
				type: "text",
			},
			{
				name: "crossFormText2",
				label: "This field is auto calculated from the previous field vale of form 1",
				type: "calculation",
				calculation: "$debugForm1.crossFormText1",
				disabled: true,
				alert: '$debugForm1.crossFormText1 ? "" : "No value found!"',
				alertColor: "danger",
			},
		],
	},
	{
		name: "repeatableAdditionalData",
		label: "Repeatables in different combinations",
		type: "drillDownGroup",
		children: [
			{
				name: "repeatableAdditionalDataA",
				label: "Depth of one",
				type: "repeatableGroup",
				children: [
					{
						type: "text",
						name: "repeatableAdditionalDataANombre",
						label: "Nombre A",
					},
				],
			},
			{
				name: "repeatableAdditionalDataB",
				label: "Depth of two",
				type: "repeatableGroup",
				children: [
					{
						type: "text",
						name: "repeatableAdditionalDataBNombre",
						label: "Nombre B",
					},
					{
						name: "repeatableAdditionalDataC",
						label: "End",
						type: "repeatableGroup",
						children: [
							{
								type: "text",
								name: "repeatableAdditionalDataCNombre",
								label: "Nombre C",
							},
						],
					},
				],
			},
		],
	},
	{
		name: "context",
		type: "drillDownGroup",
		label: "Context",
		children: [
			{
				name: "assetIdFromBaseContext",
				label: "assetId from base context",
				type: "calculation",
				dependsOn: [],
				calculation: "$ assetId",
			},
			{
				name: "projectRefFromBaseContext",
				label: "projectRef from base context",
				type: "calculation",
				dependsOn: [],
				calculation: "$ projectRef",
			},
		],
	},
];

const form1 = new Form({
	id: "debugForm1",
	name: "Formulario Prueba",
	type: "default",
	fields,
	sorted_by_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
	secondary_title_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
});
const form2 = new Form({
	id: "debugForm2",
	name: "Formulario Prueba 2",
	type: "default",
	fields,
	sorted_by_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
	secondary_title_field: "carpeta1.carpeta2.carpeta3.carpetaObservacion",
	externalDependencies: [
		{ formId: "debugForm1", dependencies: { crossFormText1: "crossFormTesting.crossFormText1" } },
	],
});

const project = new Project({
	ref: "debug",
	name: "Proyecto Debug",
	language: "es",
	configuration: {
		id: "debug",
		features: [],
		project_ref: "debug",
		default_form_id: "form-1",
		form_ids: ["debugForm1", "debugForm2"],
		configuration_filter: [],
	},
	created_at: new Date(),
});
const asset1 = new Asset({
	id: "debug-1",
	project_ref: "debug",
	name: "Activo Prueba 1",
	location: "loc1",
	created_at: new Date(),
	is_deleted: false,
});
const asset2 = new Asset({
	id: "debug-2",
	project_ref: "debug",
	name: "Activo Prueba 2",
	location: "loc2",
	created_at: new Date(),
	is_deleted: false,
});

const initDebugProject = () => {
	project.save();
	asset1.save();
	asset2.save();
	form1.save();
	form2.save();

	for (const asset of [asset1, asset2]) {
		for (const form of [form1, form2]) {
			const record = new FormRecord({
				id: `${asset.id}.${form.id}`,
				name: form.name,
				form_id: form.id,
				asset_id: asset.id,
				project_ref: project.ref,
				data: {},
				changes: [],
				delivery_status: "inProgress",
				completed: undefined,
			});
			record.save();
		}
	}
};

export const DEBUG_PROJECTS: string[] = ["debug"];
export const DEBUG_ASSETS: string[] = ["debug-1", "debug-2"];
export const DEBUG_FORMS: string[] = ["debugForm1", "debugForm2"];
export const DEBUG_RECORD_SUMMARIES: IRecordSummary[] = [
	{
		id: "debug-1.debugForm1",
		project_ref: "debug",
		asset_id: "debug-1",
		form_id: "debugForm1",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
	{
		id: "debug-1.debugForm2",
		project_ref: "debug",
		asset_id: "debug-1",
		form_id: "debugForm2",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
	{
		id: "debug-2.debugForm1",
		project_ref: "debug",
		asset_id: "debug-2",
		form_id: "debugForm1",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
	{
		id: "debug-2.debugForm2",
		project_ref: "debug",
		asset_id: "debug-2",
		form_id: "debugForm2",
		is_deleted: false,
		delivery_status: "inProgress",
		date_synced: "",
	},
];

export default initDebugProject;
