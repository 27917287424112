import React, { useEffect } from "react";
import { get, UseFormReturn } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import FormRecord, { FormValues, IDataValue } from "models/FormRecord";
import { useAppDispatch, useAppSelector } from "store";
import { actions as historyActions } from "store/slices/history";
import doRecursively from "utils/recursive/doRecursively";
import flattenObject from "utils/recursive/flattenObject";
import { FieldReferenceLibrary } from "interfaces/FieldReference";
import { FormField } from "models/Form";
import bakeHistoryInFullPath from "../../utils/bakeHistoryInFullPath";

export const useTopbar = (props: {
	fieldLibrary: FieldReferenceLibrary;
	formFields: FormField<IDataValue>[] | undefined;
	isFrozen: boolean;
	confirmDiscardFlag: "back" | "exit" | undefined;
	setConfirmDiscardFlag: (value: React.SetStateAction<"back" | "exit" | undefined>) => void;
	onSubmit: (values: FormValues, changes: string[], history: string[], exit?: boolean, stay?: boolean) => Promise<void>;
	exitForm: () => void;
	formMethods?: UseFormReturn<FormValues>;
	recordId?: string;
}) => {
	const { fieldLibrary, formFields, isFrozen, confirmDiscardFlag, setConfirmDiscardFlag, onSubmit, exitForm, formMethods, recordId } = props;
	const dispatch = useAppDispatch();
	const historySlice = useAppSelector((store) => store.history);
	const history = useHistory();
	const location = useLocation();

	// Parse pageId query parameter and update history accordingly
	useEffect(() => {
		const pageId = new URLSearchParams(location.search).get("pageId");
		if (!pageId) return;
		const fullPath = bakeHistoryInFullPath(fieldLibrary[pageId]?.path || [], historySlice.list) || [];
		dispatch(historyActions.setHistory(fullPath));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, location.search]);

	// Update pageId query parameter on page history change
	useEffect(() => {
		// Don't change pageId when entering repeatable items, keep parent's
		if (/^[0-9]+$/.test(historySlice.active ?? "")) return;
		// Otherwise, update pageId
		history.push({
			search: new URLSearchParams(historySlice.active && { pageId: historySlice.active }).toString(),
		});
	}, [history, historySlice.active, location.pathname]);

	// If pageId is InlineGroup, go to parent
	useEffect(() => {
		const allInlineGroupNames: string[] = Object.values(
			doRecursively<string>({
				fields: formFields || [],
				action: ({ path, field }) => ({ [path.join(".")]: field.name }),
				shouldAction: ({ field }) => field.type === "inlineGroup",
			}),
		);
		const pageId = new URLSearchParams(location.search).get("pageId");
		if (Object.values(allInlineGroupNames).includes(String(pageId))) {
			dispatch(historyActions.popHistory());
		}
	}, [dispatch, historySlice.active]);

	const goBack = () => {
		if (historySlice.list.length === 0) exitForm();
		else dispatch(historyActions.popHistory());
	};

	const handleBack = (exit: boolean) => {
		if (!formMethods || isFrozen) {
			return exit ? exitForm() : goBack();
		}
		let localDirtyFields;
		if (historySlice.absolutePath === "") {
			localDirtyFields = formMethods.formState.dirtyFields;
		} else {
			localDirtyFields = get(formMethods.formState.dirtyFields || {}, historySlice.absolutePath);
		}
		const shouldChangeDiscardFlag = Object.keys(localDirtyFields || {}).length > 0;
		if (shouldChangeDiscardFlag) {
			return setConfirmDiscardFlag(exit ? "exit" : "back");
		} else {
			return exit ? exitForm() : goBack();
		}
	};

	const handleSave = async () => {
		if (!formMethods) return;
		await formMethods.trigger(undefined, { shouldFocus: true }).then(console.log).catch(console.log);
		const changes = Object.keys(flattenObject(formMethods.formState.dirtyFields));
		await formMethods.handleSubmit(async (values) => {
			await onSubmit(values, changes, historySlice.list, confirmDiscardFlag === "exit", confirmDiscardFlag !== "back");
			formMethods.reset(values);
		}, console.error)();
	};

	const handleDiscard = async () => {
		if (!recordId) return;
		const record = await FormRecord.get(recordId);
		formMethods?.reset(record.data);
		if (confirmDiscardFlag === "exit") {
			exitForm();
		} else {
			goBack();
		}
	};

	return {
		handleBack,
		handleSave,
		handleDiscard,
	};
};
