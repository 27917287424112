import React, { useEffect, useState } from "react";

import { IonContent, IonItem, IonPage, IonSpinner } from "@ionic/react";
import { useParams, withRouter } from "react-router-dom";

import { getAssetsWithLatestStatus } from "api/assetStatus";
import { getForms } from "api/forms";
import { getProjects } from "api/projects";
import { getRecords, putRecords } from "api/records";
import useToken from "components/common/Auth/hooks/useToken";
import CookieProvider from "components/common/CookieProvider";
import FormComponent from "components/common/Form";
import { EmbeddedTopbar } from "components/common/Form/components/Topbar/EmbeddedTopbar";
import { buildBaseContext } from "components/common/Form/utils/buildBaseContext";
import { checkIfFrozen } from "components/common/Form/utils/checkIfFrozen";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";
import Project from "models/Project";
import { useAppDispatch } from "store";
import { actions as formActions } from "store/slices/form";
import { actions as historyActions } from "store/slices/history";
import { isNodeCompleted } from "utils/isNodeCompleted/isNodeCompleted";
import syncFunction from "utils/sync";

interface State {
	project?: Project;
	asset?: Asset;
	record?: FormRecord;
	form?: Form;
}
const EmbeddedView: React.FC = () => {
	const dispatch = useAppDispatch();
	const token = useToken();
	const { projectRef, assetId, recordId } = useParams<{
		projectRef: string;
		assetId: string;
		recordId: string;
	}>();

	const [localState, setLocalState] = useState<State>({
		project: undefined,
		asset: undefined,
		record: undefined,
		form: undefined,
	});

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			if (token) {
				await syncFunction(token, [], [], [], projectRef, assetId);
			} else {
				console.error("EmbeddedView - No token found");
				return;
			}
			// Proceed record view initialization
			const projectQuery = await getProjects([projectRef]);
			await Project.setMany(projectQuery);
			const project = await Project.get(projectRef);
			const assetsQuery = await getAssetsWithLatestStatus({ assetIds: [assetId] });
			await Asset.setMany(assetsQuery.items);
			const asset = await Asset.get(assetId);
			const recordsQuery = await getRecords([projectRef], [assetId]);
			await FormRecord.setMany(recordsQuery);
			const record = await FormRecord.get(recordId);
			const formsQuery = await getForms([projectRef]);
			await Form.setMany(formsQuery);
			const form = await Form.get(record.form_id);

			const canEdit = await Project.hasFeature(projectRef, "can-edit");

			const baseContext = await buildBaseContext(form, assetId);
			if (isCancelled) return;
			dispatch(
				formActions.setActive({
					active: { ...form },
					projectRef: project.ref,
					assetId: asset.id,
					recordId: record.id,
					baseContext,
					deliveryStatus: record.delivery_status,
					isFrozen: checkIfFrozen(record.delivery_status, canEdit),
				}),
			);
			setLocalState({ project, asset, form, record });
		};
		initialize().catch((err) => console.error(err));
		return () => {
			isCancelled = true;
		};
	}, [token, projectRef, assetId, recordId, dispatch]);

	const onSubmit = async (data: FormValues, changes: string[]) => {
		if (!localState.form) {
			throw Error("Form not available at the time of submitting");
		}
		const record = await FormRecord.get(recordId);
		if (!record) {
			throw Error("Record not available at the time of submitting");
		}
		const allChanges = [...new Set([...record.changes, ...changes])];
		if (!allChanges.length) {
			console.log("No changes detected, skipping");
			return;
		}
		const newRecord = new FormRecord({
			...record,
			data,
			changes: allChanges,
			completed: Boolean(await isNodeCompleted(localState.form, assetId, data)),
			date_synced: new Date(),
			date_updated: new Date(),
		});
		setLocalState({ ...localState, record: newRecord });
		await newRecord.save();
		dispatch(historyActions.popHistory());
		window.parent.postMessage("forms-put-records-pending", "*");
		await putRecords([newRecord]);
		window.parent.postMessage("forms-put-records-finished", "*");
	};

	useEffect(() => {
		// send a message to the parent to notify this view is ready
		window.parent.postMessage({ id: "formsEmbeddedViewReady" }, "*");
	}, []);

	return (
		<IonPage>
			<IonContent forceOverscroll={false} style={{ backgroundColor: "green" }}>
				{localState.form && localState.record ? (
					<FormComponent
						id={localState.form?.id}
						formFields={localState.form?.fields}
						record={localState.record}
						onSubmit={onSubmit}
						exitForm={() => {
							return;
						}}
						onPrint={() => {
							return;
						}}
						TopbarComponent={EmbeddedTopbar}
					/>
				) : (
					<>
						<IonItem lines="none">
							<IonSpinner name="lines-sharp-small" style={{ margin: "auto" }} />
						</IonItem>
					</>
				)}
			</IonContent>
			<CookieProvider assetId={assetId} />
		</IonPage>
	);
};

export default withRouter(EmbeddedView);
