import { dbPromise } from "../services/idb";
import { IDataValue } from "./FormRecord";

export type Expression = string; // Expressions start with "$" i.e. $name=value

export type FieldType =
	| "link" // None
	| "disclaimer" // None
	| "calculation" //IonInput
	| "date" // IonInput
	| "datetime-local" // IonInput
	| "email" // IonInput
	| "month" // IonInput
	| "number" // IonInput
	| "password" // IonInput
	| "search" // IonInput
	| "tel" // IonInput
	| "text" // IonInput
	| "time" // IonInput
	| "url" // IonInput
	| "week" // IonInput
	| "dropdown" // IonSelect
	| "radio" // IonRadioGroup
	| "textarea" // IonTextarea
	| "location" // Custom
	| "files" // Custom
	| "images" // Custom
	| "sketch" // Custom
	| "inlineGroup" // Custom
	| "drillDownGroup" // Custom
	| "repeatableGroup" // Custom
	| "drawings"; // Custom

export interface FieldChoice {
	label: string;
	value: string;
	[extraKey: string]: IDataValue; // To be used as context for filters
}

export interface FormField<T = IDataValue> {
	isInteger?: boolean;
	step?: string;
	min?: string | number; // Useful when type is "number" and you need to set a minimum value.
	max?: string | number; // Useful when type is "number" and you need to set a maximum value.
	name: string; // A "camelCase" identifier for the field. Should be unique within a form. Otherwise, first value found in the hierarchy will be used instead.
	label: string; // A short, human legible name for this field.
	shortLabel?: string; // Shorter version when the space to show the label is reduced.
	type: FieldType; // A valid field type that defines the data type and the type of input element to be displayed.

	description?: string; // Will go into placeholder when the input element supports it. Idea is to implement a "hint" functionality based on this later.
	alert?: Expression; // Will display an alert message with the output of the expressio
	alertColor?: "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark"; // Color for the alert message
	alertIcon?: "informationCircleOutline" | "alertCircleOutline";

	dependsOn?: string[]; // List of variables that will be available in the context of Expressions.
	baseContext?: Record<string, IDataValue>; // Object of values to be injected in expression context.

	defaultValue?: T; // Default value for this field, if given.
	required?: boolean | Expression; // Wether if filling this field should be convenient in order to save the form record. The user can still save it.
	hardRequired?: boolean | Expression; // Same as required, but it does not allow the user to save the record if it's not filled.
	relevant?: boolean | Expression; // Wether if the field should be displayed.
	disabled?: boolean | Expression; // Wether if the field is enabled for user input or not
	validate?: Expression; // A true/false expression that tells if the selected value is valid.
	sortChoices?: "ascending" | "descending"; // Toggle sort by label. Undefined for no sort.

	// Only applicable to 'calculation' types.
	calculation?: Expression; // An expression that returns an auto-calculated value for the field.

	// Only applicable to 'dropdown' & 'radio' types.
	choices?: FieldChoice[]; // A list of valid choices. Choices that do not pass the validate Expression will not be displayed. If zero choices available, won't be displayed.
	multiple?: boolean; // Only used in dropdown or radio types, to select several choices for the same field at once.

	// Only applicable to 'repetableGroup' descendants
	filter?: "exact" | "includes"; // All fields within a repeatable (up to the next repeatable in the hierarchy) will be added as repeatable filters

	// Only applicable to repeatables
	itemTitle?: Expression; // Expression that returns a string to be used as item label.
	itemSubtitle?: Expression; // Expression that returns a string to be used as secondary item label.
	itemIconConfig?: {
		label?: string; // Label that will be displayed in the repeatable table header to indicate what the icon represents (max 6 chars). Defaults to the field's label.
		color?: Expression; // Expression that returns a valid color string (self value is available in context). Defaults gray
		icon?: Expression; // Expression that returns a valid icon id -from ionicons library- (self value is available in context). Defaults to circle.
	};
	sortBy?: "title" | "subtitle";
	sortMode?: "ascending" | "descending";
	displayRefNo?: boolean;

	// Only applicable to 'inlineGroup', 'drilldownGroup' & 'repeataleGroup'
	children?: FormField[]; // Another set of fields to be
}

export interface IExternalDependency {
	formId: string;
	dependencies: { [key: string]: string }; // {[Key]: [Fullpath]}
}

export interface IRawForm {
	id: string;
	name: string;
	type: "default" | "issue";
	fields: FormField[];
	sorted_by_field?: string; // Data field that will be used to sort all record in the list views
	secondary_title_field?: string; // Data field that will be used as a subtitle under the record name
	is_deleted?: boolean;
	externalDependencies?: IExternalDependency[];
}

export default class Form implements IRawForm {
	id: string;
	name: string;
	type: "default" | "issue";
	fields: FormField[];
	sorted_by_field?: string;
	secondary_title_field?: string;
	is_deleted: boolean;
	externalDependencies?: IExternalDependency[];

	constructor(form: IRawForm) {
		this.id = form.id;
		this.name = form.name;
		this.type = form.type;
		this.fields = form.fields;
		this.sorted_by_field = form.sorted_by_field;
		this.secondary_title_field = form.secondary_title_field;
		this.is_deleted = form.is_deleted ?? false;
		this.externalDependencies = form.externalDependencies || [];
	}

	static async getAllKeys() {
		return await (await dbPromise).getAllKeys("forms");
	}

	static async get(id: string, includeDeleted = false) {
		return await (await dbPromise).get("forms", id).then((r) => {
			if (r === undefined) throw new Error(`Key ${id} not found`);
			if (r.is_deleted && !includeDeleted)
				throw new Error(`Key ${id} is archived -marked as deleted- and thus, not readily retrievable`);
			return new Form(r);
		});
	}

	static async getMany(ids: string[], includeDeleted = false) {
		const tx = (await dbPromise).transaction("forms", "readwrite");
		const store = tx.objectStore("forms");
		const data = await Promise.all(ids.map((id) => store.get(id)));
		await tx.done;
		if (includeDeleted) return data.filter((it): it is Form => !!it);
		return data.filter((it): it is Form => !!it && !it.is_deleted);
	}

	static async getAll(includeDeleted = false) {
		const data = await (await dbPromise).getAll("forms").then((r) => r.map((ri) => new Form(ri)));
		if (includeDeleted) return data;
		return data.filter((it) => !it.is_deleted);
	}

	static async set(data: Form) {
		await (await dbPromise).put("forms", data);
		return new Form(data);
	}

	static async setMany(data: Form[]) {
		const tx = (await dbPromise).transaction("forms", "readwrite");
		const store = tx.objectStore("forms");
		await Promise.all(data.map((it) => store.put(it)));
		await tx.done;
	}

	static async deleteMany(ids: string[]) {
		const data = await Form.getMany(ids);
		await Form.setMany(data.map((it) => new Form({ ...it, is_deleted: true })));
	}

	delete() {
		return Form.set(new Form({ ...this, is_deleted: true }));
	}

	save() {
		return Form.set(this);
	}
}
