export interface QuotaInfo {
	usage: number | undefined;
	quota: number | undefined;
}

export const estimateStorageQuota = async () => {
	const quotaInfo: QuotaInfo = {
		usage: undefined,
		quota: undefined,
	};
	if (navigator.storage && navigator.storage.estimate) {
		// Find out quota
		const quota = await navigator.storage.estimate();
		if (quota && quota.usage !== undefined && quota.quota !== undefined) {
			quotaInfo.usage = quota.usage;
			quotaInfo.quota = quota.quota;
			const percentageUsed = (quota.usage / quota.quota) * 100;
			console.info(`You've used ${percentageUsed.toFixed(1)}% of the available storage.`);
			const remaining = quota.quota - quota.usage;
			console.info(`You can write up to ${(remaining / 1e6).toFixed(0)} more MB.`);
		}
	} else {
		console.log("navigator.storage.estimate API unavailable");
	}
	return quotaInfo;
};

export const requestStoragePersistance = () => {
	// Try to make sure we can persist data
	if (navigator.storage && navigator.storage.persist) {
		navigator.storage.persist().then((persistent) => {
			if (persistent) {
				console.info("Successfully set persistent storage");
			} else {
				console.info("Could not ensure persisting storage");
			}
		});
	} else {
		console.log("navigator.storage.persist API unavailable");
	}
};
