import React, { useEffect, useState } from "react";

import { IonContent, IonItem, IonPage, IonSpinner } from "@ionic/react";
import { useHistory, useParams, withRouter } from "react-router-dom";

import useToken from "components/common/Auth/hooks/useToken";
import CookieProvider from "components/common/CookieProvider";
import FormComponent from "components/common/Form";
import { Topbar } from "components/common/Form/components/Topbar/Topbar";
import { buildBaseContext } from "components/common/Form/utils/buildBaseContext";
import { checkIfFrozen } from "components/common/Form/utils/checkIfFrozen";
import Asset from "models/Asset";
import Form from "models/Form";
import FormRecord, { FormValues } from "models/FormRecord";
import Project from "models/Project";
import { useAppDispatch } from "store";
import { actions as formActions } from "store/slices/form";
import { actions as historyActions } from "store/slices/history";
import syncFunction from "utils/sync";
import { isNodeCompleted } from "utils/isNodeCompleted/isNodeCompleted";

interface State {
	project?: Project;
	asset?: Asset;
	record?: FormRecord;
	form?: Form;
}
const RecordView: React.FC = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const token = useToken();
	const { projectRef, assetId, recordId } = useParams<{
		projectRef: string;
		assetId: string;
		recordId: string;
	}>();

	const [localState, setLocalState] = useState<State>({
		project: undefined,
		asset: undefined,
		record: undefined,
		form: undefined,
	});

	useEffect(() => {
		let isCancelled = false;
		const initialize = async () => {
			// If using autoSync option, automatically sync asset (download)
			const autoSync = new URLSearchParams(location.search).get("autoSync");
			if (autoSync) {
				if (token) await syncFunction(token, [], [], [], projectRef, assetId);
			}
			// Proceed record view initialization
			const project = await Project.get(projectRef);
			const asset = await Asset.get(assetId);
			const record = await FormRecord.get(recordId);
			const form = await Form.get(record.form_id);

			const canEdit = await Project.hasFeature(projectRef, "can-edit");

			const baseContext = await buildBaseContext(form, assetId);
			if (isCancelled) return;
			dispatch(
				formActions.setActive({
					active: { ...form },
					projectRef: project.ref,
					assetId: asset.id,
					recordId: record.id,
					baseContext,
					deliveryStatus: record.delivery_status,
					isFrozen: checkIfFrozen(record.delivery_status, canEdit),
				}),
			);
			setLocalState({ project, asset, form, record });
		};
		initialize().catch((err) => console.error(err));
		return () => {
			isCancelled = true;
		};
	}, [projectRef, assetId, recordId, dispatch]);

	const exitForm = async () => {
		const { project } = localState;
		if (!project) return;
		const numForms = project?.configuration.form_ids.length;
		if (numForms <= 1) history.push(`/${projectRef}`);
		else history.push(`/${projectRef}/${assetId}/`);
		await dispatch(historyActions.clearHistory());
	};

	const onSubmit = async (data: FormValues, changes: string[], history: string[], exit = false, stay = false) => {
		if (!localState.form) {
			throw Error("Form not available at the time of submitting");
		}
		const record = (await FormRecord.get(recordId)) || localState.record;
		const allChanges = [...new Set([...record.changes, ...changes])];
		const newRecord = new FormRecord({
			...record,
			data,
			changes: allChanges,
			completed: Boolean(await isNodeCompleted(localState.form, assetId, data)),
		});
		await newRecord.save();
		if (stay) return;
		if (exit || history.length === 0) {
			exitForm();
		} else {
			dispatch(historyActions.popHistory());
		}
	};

	const onPrint = () => {
		history.push(`/report/${recordId}`);
	};

	return (
		<IonPage>
			<IonContent forceOverscroll={false}>
				{localState.form && localState.record ? (
					<FormComponent
						id={localState.form?.id}
						formFields={localState.form?.fields}
						record={localState.record}
						onSubmit={onSubmit}
						exitForm={exitForm}
						onPrint={onPrint}
					/>
				) : (
					<>
						<Topbar exitForm={exitForm} onSubmit={onSubmit} onPrint={onPrint} />
						<IonItem lines="none">
							<IonSpinner name="lines-sharp-small" style={{ margin: "auto" }} />
						</IonItem>
					</>
				)}
			</IonContent>
			<CookieProvider assetId={assetId} />
		</IonPage>
	);
};

export default withRouter(RecordView);
