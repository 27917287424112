import React, { useContext } from "react";
import { get } from "react-hook-form";

import { IImage } from "interfaces/IImage";
import { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { ReportCoordinates } from "views/ReportView/components/widgets/ReportCoordinates";
import { ReportImage } from "views/ReportView/components/widgets/ReportImage";
import { ReportGroup } from "views/ReportView/components/widgets/ReportGroup";
import { ReportText } from "views/ReportView/components/widgets/ReportText";
import { ReportRepeatable } from "views/ReportView/components/widgets/ReportRepeatable";
import useReportFieldTemplate from "../hooks/useReportFieldTemplate";
import { ReportContext } from "./ReportRenderer";

interface ReportWidgetProps {
	formField: FormField;
	dataToRender: FormValues;
	allData: FormValues;
	ancestorHistory: string[];
}
export const WidgetFactory: React.FC<ReportWidgetProps> = (props: ReportWidgetProps) => {
	const { dataToRender: data, allData, formField, ancestorHistory } = props;
	const { fieldReferenceLibrary, changes, baseContext } = useContext(ReportContext);
	const { isRelevant } = useReportFieldTemplate(
		formField,
		allData,
		fieldReferenceLibrary,
		ancestorHistory,
		baseContext,
	);
	const isGroup = formField.type === "inlineGroup" || formField.type === "drillDownGroup";
	const fieldValue = get(data, formField.name, formField.defaultValue);

	const shouldOmitUndefined = (fieldValue === undefined || fieldValue === "") && !isGroup;
	if (shouldOmitUndefined) {
		return <></>;
	}

	const path = [ancestorHistory, formField.name].join(".");
	const hasChanged = changes?.includes(path);

	if (!isRelevant()) return <React.Fragment />;

	if (formField.type === "repeatableGroup") {
		return <ReportRepeatable formField={formField} value={fieldValue} ancestorHistory={ancestorHistory} data={data} />;
	} else if (isGroup) {
		return <ReportGroup formField={formField} value={fieldValue} ancestorHistory={ancestorHistory} data={data} />;
	} else if (formField.type === "files") {
		return <React.Fragment />;
	} else if (formField.type === "images") {
		return <ReportImage formField={formField as FormField<IImage[]>} value={fieldValue} highlight={hasChanged} />;
	} else if (formField.type === "location") {
		return <ReportCoordinates formField={formField} value={fieldValue} highlight={hasChanged} />;
	} else {
		return <ReportText formField={formField} value={fieldValue} highlight={hasChanged} />;
	}
};
