import FormRecord from "models/FormRecord";
import Form from "models/Form";
import doRecursively from "utils/recursive/doRecursively";
import { getSignedUrl } from "utils/sync/getSignedUrl";

export async function cacheFloorplans(assetId: string | undefined, projectRef: string, authorization: string) {
	const allRecords = await (assetId ? FormRecord.byAssetId(assetId) : FormRecord.byProjectRef(projectRef));
	const formIds = allRecords.map((r) => r.form_id);
	const forms = await Form.getMany(formIds);
	const filepathsForAllForms = forms
		.map((form) => {
			const filepaths: string[] = Object.values(
				doRecursively({
					fields: form.fields,
					action: ({ field }) => {
						const isDrawing = field.type === "drawings";
						if (isDrawing) {
							return { [field.name]: field.choices?.map((choice) => String(choice.filepath)) || [] };
						}
						return { [field.name]: [] };
					},
				}),
			).flat();
			return filepaths;
		})
		.flat();
	const promises = getSignedUrl(
		filepathsForAllForms.map((it) => ({
			filepath: it,
			thumbnail: false,
			type: "drawing",
		})),
		authorization,
	);
	const signedUrls = await promises;
	for (const key in signedUrls) {
		const cache = await caches.open("dhub-forms-images");
		const currentSignedUrl = signedUrls[key];
		if (currentSignedUrl) {
			const cacheKey = `drawings/${key}`;
			const exists = await cache.match(cacheKey);
			if (exists) continue;
			const requestToS3 = await fetch(currentSignedUrl);
			cache.put(cacheKey, requestToS3);
		}
	}
	return signedUrls;
}
