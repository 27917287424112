import React, { useEffect, useState } from "react";

import { IonAlert, IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";

import { Auth } from "components/common/Auth/Auth";
import useRefreshCFToken from "components/common/Auth/hooks/useRefreshCFToken";
import { CheckOnline } from "components/common/CheckOnline/CheckOnline";
import ReportView from "views/ReportView";
import InstallPrompt from "./components/common/InstallPrompt";
import SilentDataFetcher from "./components/common/SilentDataFetcher";
import initDebugProject from "./mock/debug";
import { useAppSelector } from "./store";
import { estimateStorageQuota, requestStoragePersistance } from "./utils/storageUtils";
import AdminView from "./views/AdminView/AdminView";
import AssetsView from "./views/AssetsView/AssetsView";
import ProjectsView from "./views/ProjectsView/ProjectsView";
import RecordsView from "./views/RecordsView/RecordsView";
import RecordView from "./views/RecordView/RecordView";
import EmbeddedView from "views/EmbeddedView";

// /* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

// /* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

// /* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { useIsAuthenticated } from "@azure/msal-react";
import "./theme/colors.css";
import "./theme/overrides.css";
import { EmbeddedCFGuard } from "views/EmbeddedView/EmbeddedCFGuard";
import { handleCfRedirect } from "utils/handleCfRedirect";

setupIonicReact();

interface IProps {
	match?: {
		url: string;
		[x: string]: unknown;
	};
}

const App: React.FC<IProps> = () => {
	const { serviceWorkerUpdated, serviceWorkerRegistration } = useAppSelector((state) => state.sw);

	const [pendingUpdateDismissed, setPendingUpdateDismissed] = useState(false);
	const online = useAppSelector((state) => state.connection.isOnline);
	const { t, i18n } = useTranslation();

	const isAzureAuthenticated = useIsAuthenticated();
	useRefreshCFToken();

	handleCfRedirect();

	const updateServiceWorker = () => {
		if (serviceWorkerRegistration === null) {
			console.warn("Service worker registration unavailable in redux store");
			return;
		}
		const registrationWaiting = serviceWorkerRegistration.waiting;
		if (registrationWaiting) {
			registrationWaiting.postMessage({ type: "SKIP_WAITING" });
			registrationWaiting.addEventListener("statechange", (e) => {
				if (e.target === null) return;
				const target = e.target as unknown as ServiceWorker;
				if (target.state === "activated") {
					window.location.reload();
				}
			});
		}
	};

	// Inform us on storage quota
	useEffect(() => {
		estimateStorageQuota();
		requestStoragePersistance();
	}, []);

	// Initialize debug project
	useEffect(() => {
		if (window.location.hostname === "localhost") initDebugProject();
	}, []);

	const apiKey = new URLSearchParams(window.location.search).get("api_key");

	return (
		<IonReactRouter>
			<React.StrictMode>
				<Switch>
					<Route exact path="/cdn-cgi/access/authorized">
						<Redirect to="/" />
					</Route>
					<Route exact path="/report/:recordId/">
						{<ReportView />}
					</Route>
					<Route exact path="/embeddedCFGuard/:redirectUrl">
						{<EmbeddedCFGuard />}
					</Route>
					<Route exact path="/embedded/:projectRef/:assetId/:recordId">
						<Auth interactWithPopup={true}>
							<EmbeddedView />
						</Auth>
					</Route>
					<Route path="/">
						<IonApp>
							<IonAlert
								header={i18n.format(t("please_update"), "capitalizeEveryWord")}
								message={
									i18n.format(t("new_forms_version"), "capitalize") +
									i18n.format(t("update_forms_question"), "capitalize")
								}
								isOpen={serviceWorkerUpdated && !pendingUpdateDismissed}
								backdropDismiss={false}
								buttons={[
									{
										text: i18n.format(t("cancel"), "capitalize"),
										role: "cancel",
										cssClass: "secondary",
										handler: () => {
											setPendingUpdateDismissed(true);
											console.log("SW update cancelled");
										},
									},
									{
										text: i18n.format(t("update"), "capitalize"),
										handler: updateServiceWorker,
									},
								]}
							/>
							<CheckOnline />
							{!isAzureAuthenticated && !apiKey && online ? (
								<Auth />
							) : (
								<Switch>
									<Route exact path="/admin">
										<AdminView />
									</Route>
									<Route exact path="/:projectRef/:assetId/:recordId">
										<RecordView />
									</Route>
									<Route exact path="/:projectRef/:assetId">
										<RecordsView />
									</Route>
									<Route exact path="/:projectRef">
										<AssetsView />
									</Route>
									<Route exact path="/">
										<ProjectsView />
									</Route>
								</Switch>
							)}
							<SilentDataFetcher />
							<InstallPrompt />
						</IonApp>
					</Route>
				</Switch>
			</React.StrictMode>
		</IonReactRouter>
	);
};

export default App;
