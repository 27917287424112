import { UseFormReturn, useWatch } from "react-hook-form";

import Form, { FormField } from "models/Form";
import { FormValues } from "models/FormRecord";
import { useAppSelector } from "store";
import doRecursively from "utils/recursive/doRecursively";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import bakeHistoryInFullPath from "../utils/bakeHistoryInFullPath";
import { isNodeCompleted } from "utils/isNodeCompleted/isNodeCompleted";

export const useGroupFilledIn = (field: FormField, formMethods: UseFormReturn<FormValues>, itemIdx?: number) => {
	const fieldLibrary = useAppSelector((state) => state.form.fieldReferenceLibrary);
	const activeForm = useAppSelector((state) => state.form.active);
	const history = useAppSelector((state) => state.history.list);
	const { assetId } = useParams<{ assetId: string }>();
	const [isComplete, setIsComplete] = useState<boolean | undefined>(undefined);

	const rawAbsSelfPath: string[] = fieldLibrary[field.name].path || field.name;
	const absSelfPath = bakeHistoryInFullPath(rawAbsSelfPath, history) || [];

	const dependencies = [...new Set(Object.values(getDependenciesToWatch([field])).flat())];

	useWatch({
		name: dependencies,
		control: formMethods.control,
	});

	const data = formMethods.getValues();

	if (!field.children) return true;

	const form = activeForm && new Form(activeForm);

	useEffect(() => {
		if (!form || !assetId || !data) return;
		if (field.type === "repeatableGroup" && itemIdx === undefined) {
			isNodeCompleted(form, assetId, data, [field], absSelfPath.slice(0, -1)).then(setIsComplete);
		} else {
			isNodeCompleted(
				form,
				assetId,
				data,
				field.children,
				itemIdx !== undefined ? [...absSelfPath, itemIdx.toString()] : absSelfPath,
			).then(setIsComplete);
		}
	}, [form, assetId, data]);

	return Boolean(isComplete);
};

export const getDependenciesToWatch = (childrenFields: FormField[]) => {
	// Idea here is that we only need to keep track of dependencies of fields that have
	// expressions for "relevant" and / or "required" and which might change
	// while our component is on screen. This means that we only need to look recursively
	// within inline groups, as other types of groups are not rendered.
	return doRecursively({
		fields: childrenFields,
		action: ({ path, field }) => ({ [path.join(".")]: field.dependsOn ?? [] }),
		shouldAction: ({ field }) => typeof field.relevant === "string" || typeof field.required === "string",
		shouldDive: ({ field }) => field.type === "inlineGroup" && !!(field.relevant ?? true),
	});
};
