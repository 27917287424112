import { useMemo } from "react";

import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { Control, useWatch } from "react-hook-form";

import { FormField } from "models/Form";
import { IDataValue } from "models/FormRecord";
import { useAppSelector } from "store";

import { FieldReferenceLibrary } from "interfaces/FieldReference";
import bakeHistoryInFullPath from "../utils/bakeHistoryInFullPath";

export const getDependencies = (field: FormField, fieldLibrary: FieldReferenceLibrary, history: string[]) => {
	const rawDependencies = [field.name, ...(field.dependsOn || [])];
	const dependencies = rawDependencies.map((dep) => bakeHistoryInFullPath(fieldLibrary[dep]?.path, history) || [dep]);
	return { dependencies, rawDependencies };
};

export const buildContext = (
	dependencies: string[][],
	rawDependencies: string[],
	rawContext: IDataValue[],
	baseContext: Record<string, IDataValue> = {},
) => {
	const newContext: Record<string, IDataValue> = { ...baseContext };
	// Set it with the raw dependency (dependency field name)
	for (const [idx, dep] of rawDependencies.entries()) {
		set(newContext, dep, cloneDeep(rawContext[idx]));
	}
	// Set it again with the actual path as key
	for (const [idx, path] of dependencies.entries()) {
		set(newContext, path, cloneDeep(rawContext[idx]));
	}
	return newContext;
};

const useFieldContext = (field: FormField, control: Control, customHistory?: string[]) => {
	const { assetId, projectRef } = useAppSelector((state) => state.form);
	const history = useAppSelector((state) => state.history.list);
	const { fieldReferenceLibrary, baseContext, isFrozen } = useAppSelector((state) => state.form);

	// Get dependencies (also keep the raw names for each dependency)
	const { dependencies, rawDependencies } = useMemo(
		() => getDependencies(field, fieldReferenceLibrary, customHistory || history),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[history.length, customHistory?.length, field.name],
	);

	// Subscribe to dependencies
	const rawContext = useWatch({
		name: dependencies.filter((dep) => !dep.includes("_")).map((dep) => dep.join(".")),
		control,
	});

	// We are confident assetId and projectRef are not null. This hook is used once you are inside a form.
	const base = {
		external: baseContext,
		...field.baseContext,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		assetId: assetId!,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		projectRef: projectRef!,
	};
	// Build a nice context based on retrieved values
	const context = useMemo(
		() => buildContext(dependencies, rawDependencies, rawContext, base),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[rawContext],
	);

	const name = dependencies[0].join(".") || field.name;
	return { name, dependencies, context, isFrozen };
};

export default useFieldContext;
