import { IonIcon, IonSpinner } from "@ionic/react";
import { checkmarkCircleOutline, ellipseOutline } from "ionicons/icons";
import React, { useEffect } from "react";

import Form from "models/Form";
import FormRecord from "models/FormRecord";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store";
import { isNodeCompleted } from "utils/isNodeCompleted/isNodeCompleted";

export const CompletitionStatusIcon: React.FC<{
	record?: FormRecord;
	style?: Record<string, string>;
}> = (props) => {
	const { record, style } = props;
	const { assetId } = useParams<{
		assetId: string;
	}>();
	const activeForm = useAppSelector((state) => state.form.active);

	useEffect(() => {
		const form = activeForm && new Form(activeForm);

		if (form && record && (record.completed === undefined || record.completed === null)) {
			console.log("CompletitionStatusIcon - Because record.completed is undefined/null, we are checking if it is completed");
			isNodeCompleted(form, assetId, record?.data).then((completed) => {
				FormRecord.get(record.id).then((record) => {
					FormRecord.set(new FormRecord({ ...record, completed }));
				});
			});
		}
	}, [record?.completed]);

	if (record?.completed === undefined)
		return (
			<IonSpinner
				name="lines-sharp-small"
				color="medium"
				style={{
					height: "1.25rem",
					width: "1.25rem",
					...style,
				}}
			/>
		);
	const fillColor = record?.completed ? "var(--ion-color-success)" : "#CBD5E0";
	const svg = record?.completed ? checkmarkCircleOutline : ellipseOutline;
	return (
		<IonIcon
			slot="end"
			size="small"
			style={{
				color: fillColor,
				height: "1.25rem",
				width: "1.25rem",
				...style,
			}}
			icon={svg}
		/>
	);
};
