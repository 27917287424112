import get from "lodash.get";

import { buildBaseContext } from "components/common/Form/utils/buildBaseContext";
import buildFieldReferenceLibrary from "components/common/Form/utils/buildReferenceLibrary";
import { FieldReferenceLibrary } from "interfaces/FieldReference";
import Form, { FormField } from "models/Form";
import { FormValues, IDataValue } from "models/FormRecord";

import { buildContext, getDependencies } from "../../components/common/Form/hooks/useFieldContext";
import { buildIsDisabled, buildIsRelevant, buildIsRequired } from "../../components/common/Form/hooks/useFieldTemplate";

/**
 * Checks if a record is completed.
 * @param form The form to check against.
 * @param assetId The assetId of the record to check.
 * @param data The values of the node to check.
 * @param fields The fields to check. If not provided, all fields in the form will be checked. You can check a field on any level of the hierarchy.
 * @param currPath The current path. If not provided, the root path will be used. Used for repeatable groups.
 *
 * @returns A boolean indicating if the record is completed, or undefined if the form or assetId is not provided.
 *
 **/
export async function isNodeCompleted(
	form: Form,
	assetId: string,
	data: FormValues<IDataValue>,
	fields?: FormField[],
	currPath?: string[],
): Promise<boolean> {
	const fieldLibrary = buildFieldReferenceLibrary(form.fields || []);
	const baseContext = await buildBaseContext(form, assetId);
	const isComplete = recursivelyCheckFilledIn({
		fields: fields || form.fields || [],
		data: data,
		fieldLibrary,
		baseContext,
		currPath,
	});
	return isComplete;
}

export interface RecursivelyCheckFulfilledProps {
	fields: FormField[];
	data: FormValues;
	currPath?: string[];
	fieldLibrary: FieldReferenceLibrary;
	baseContext?: FormValues;
}
/**
 * This is a recursive function that is only exported for the tests. Not intenteded as a public function.
 * **/
export const recursivelyCheckFilledIn = (props: RecursivelyCheckFulfilledProps): boolean => {
	const { fields, data, fieldLibrary, baseContext = {}, currPath = [] } = props;

	for (const field of fields) {
		const path = [...currPath, field.name];
		const { isRelevant, isDisabled, isRequired } = evaluateFieldExpressions(
			field,
			data,
			fieldLibrary,
			baseContext,
			path,
		);
		if (!isRelevant || isDisabled) continue;

		// Repeatables
		if (field.children?.length && field.type === "repeatableGroup") {
			// TODO: For some unknown reason it's possible to find nulls in the repeatable item lists
			const items: (FormValues | null)[] = get(data, path, []);
			for (const [idx, item] of items.entries()) {
				if (item && item._is_deleted) continue;
				const fullfilled = recursivelyCheckFilledIn({
					fields: field.children,
					data,
					currPath: [...path, idx.toString()],
					fieldLibrary,
					baseContext,
				});
				if (!fullfilled) {
					// console.log("Group not filled-in. Found missing required field:", field.name);
					return false;
				}
			}
			// Group fields
		} else if (field.children?.length) {
			const fullfilled = recursivelyCheckFilledIn({
				fields: field.children,
				data,
				currPath: path,
				fieldLibrary,
				baseContext,
			});
			if (!fullfilled) {
				// console.log("Group not filled-in. Found missing required field:", field.name);
				return false;
			}
			// We are ok with simple fields that are irrelevant or definitely not required
		} else {
			const value: IDataValue = get(data, path);
			const isEmpty = value === null || value === undefined || value === "";
			const fulfilled = !isRelevant || isDisabled || !isRequired || !isEmpty;
			if (!fulfilled) {
				// console.log("Group not filled-in. Found missing required field:", field.name);
				return false;
			}
		}
	}
	return true;
};

const evaluateFieldExpressions = (
	field: FormField,
	data: FormValues,
	fieldLibrary: FieldReferenceLibrary,
	externalContext: FormValues,
	path: string[],
) => {
	// We are ok with simple fields that are irrelevant or definitely not required
	const relevant = field.relevant ?? true;
	const required = field.required ?? false;
	const disabled = field.disabled ?? false;

	// Early scape if we are just dealing with booleans and we don't need any evaluations
	if (typeof relevant === "boolean" && typeof required === "boolean" && typeof disabled === "boolean")
		return { isRelevant: relevant, isRequired: required, isDisabled: disabled };

	// Perform expression evaluation as required
	const { dependencies, rawDependencies } = getDependencies(field, fieldLibrary, path);
	const rawContext = dependencies.map((depPath) => get(data, depPath) as IDataValue);
	const context = buildContext(dependencies, rawDependencies, rawContext, {
		external: externalContext,
		...field.baseContext,
	});

	const isRelevant = typeof relevant === "string" ? buildIsRelevant(field, context)() : relevant;
	const isRequired = typeof required === "string" ? buildIsRequired(field, context)() : required;
	const isDisabled = typeof disabled === "string" ? buildIsDisabled(field, context)() : disabled;

	return { isRelevant, isRequired, isDisabled };
};
